import React from 'react';
import {
  CartesianGrid,
  Area,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

export const Chart = ({ blok }) => {
  const chartData = blok.data?.tbody.map(item => ({
    name: item.body[0].value,
    value: item.body[1].value,
  }));

  return (
    <>
      <div className="md:hidden" style={{ height: '460px' }}>
        <ResponsiveContainer width="99%">
          <AreaChart
            data={chartData}
            margin={{
              bottom: 10,
              left: 10,
              right: 10,
              top: 10,
            }}
            width={300}
            height={460}
          >
            {' '}
            <text x="50%" y="100%" textAnchor="middle">
              <tspan
                fill="#2e384d"
                fontSize="14"
                fontWeight="400"
                fontFamily="Gilroy-Regular"
              >
                Quelle:{` `}
              </tspan>
              <tspan
                fill="#2e384d"
                fontSize="14"
                fontWeight="500"
                fontFamily="Gilroy-SemiBold"
              >
                {blok.datasource}
              </tspan>
            </text>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#97BDE4" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#97BDE4" stopOpacity={0} />
              </linearGradient>
            </defs>
            <text x="15%" y={20} textAnchor="end">
              <tspan
                fill="#2e384d"
                fontSize="18"
                fontWeight="700"
                fontFamily="Gilroy-Regular"
              >
                {blok.name}
              </tspan>
            </text>
            <XAxis
              dataKey="name"
              interval={Number(blok.intervalX * 2)}
              allowDecimals={false}
            />
            <YAxis width={Number(blok.axisYwidth)} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={(value, name, props) => [
                blok.valueFormat ? eval(blok?.valueFormat) : value,
                blok.labelFormat ? eval(blok?.labelFormat) : name,
                props,
              ]}
            />
            <Area
              activeDot={{ r: 4 }}
              dot={{ r: 3 }}
              type="monotone"
              dataKey="value"
              stroke="#304C77"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden md:block lg:hidden" style={{ height: '500px' }}>
        <ResponsiveContainer width="99%">
          <AreaChart
            data={chartData}
            margin={{ bottom: 12, right: 10, top: 10 }}
            width={566}
            height={500}
          >
            <text x="50%" y="100%" textAnchor="middle">
              <tspan
                fill="#2e384d"
                fontSize="14"
                fontWeight="400"
                fontFamily="Gilroy-Regular"
              >
                Quelle:{` `}
              </tspan>
              <tspan
                fill="#2e384d"
                fontSize="14"
                fontWeight="500"
                fontFamily="Gilroy-SemiBold"
              >
                {blok.datasource}
              </tspan>
            </text>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#97BDE4" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#97BDE4" stopOpacity={0} />
              </linearGradient>
            </defs>
            <text x="15%" y={20} textAnchor="middle">
              <tspan
                fill="#2e384d"
                fontSize="18"
                fontWeight="700"
                fontFamily="Gilroy-Regular"
              >
                {blok.name}
              </tspan>
            </text>
            <XAxis
              dataKey="name"
              interval={Number(blok.intervalX)}
              width={464}
              allowDecimals={false}
            />
            <YAxis width={Number(blok.axisYwidth)} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={(value, name, props) => [
                blok.valueFormat ? eval(blok?.valueFormat) : value,
                blok.labelFormat ? eval(blok?.labelFormat) : name,
                props,
              ]}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#304C77"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden lg:block" style={{ height: '500px' }}>
        <ResponsiveContainer width="99%">
          <AreaChart
            data={chartData}
            margin={{ bottom: 12, right: 10, top: 10 }}
            width={736}
            height={500}
          >
            {/* eslint-disable-next-line react/no-unknown-property */}
            <text x="100%" y="100%" textAnchor="end" scaleToFit>
              <tspan
                fill="#2e384d"
                fontSize="14"
                fontWeight="400"
                fontFamily="Gilroy-Regular"
              >
                Quelle:{` `}
              </tspan>
              <tspan
                fill="#2e384d"
                fontSize="14"
                fontWeight="500"
                fontFamily="Gilroy-SemiBold"
              >
                {blok.datasource}
              </tspan>
            </text>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#97BDE4" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#97BDE4" stopOpacity={0} />
              </linearGradient>
            </defs>
            <text x="15%" y={20} textAnchor="middle">
              <tspan
                fill="#2e384d"
                fontSize="18"
                fontWeight="700"
                fontFamily="Gilroy-Regular"
              >
                {blok.name}
              </tspan>
            </text>
            <XAxis
              dataKey="name"
              interval={Number(blok.intervalX)}
              width={564}
              allowDecimals={false}
            />
            <YAxis width={Number(blok.axisYwidth)} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={(value, name, props) => [
                blok.valueFormat ? eval(blok?.valueFormat) : value,
                blok.labelFormat ? eval(blok?.labelFormat) : name,
                props,
              ]}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#304C77"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Chart;
