import React from 'react';
import ReactPlayer from 'react-player';
import SbEditable from 'storyblok-react';
import cx from 'classnames';
import Component from '../index';

const Masonry = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container grid grid-cols-4 text-center gap-14 lg:gap-8 street-masonry lg:grid-cols-12">
      {blok?.data.length > 2 ? (
        <div className="flex flex-col items-center justify-center col-span-4 bg-gray-100 lg:col-span-12 street-data lg:flex-row">
          {blok?.data.map(blk => (
            <Component blok={blk} key={blk._uid} />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center col-span-4 row-span-2 bg-gray-100 xl:row-span-1 lg:col-span-7 street-data lg:flex-row">
          {blok?.data.map(blk => (
            <Component blok={blk} key={blk._uid} />
          ))}
        </div>
      )}
      {blok.backgroundVideo && (
        <div className="relative flex flex-col col-span-4 p-4 lg:hidden square-video lg:col-span-12">
          <ReactPlayer
            className="react-player"
            url={blok.backgroundVideo}
            height="100%"
            width="100%"
            loop
            muted
            playing
            playsinline
          />
        </div>
      )}
      {blok?.data.length > 2 ? (
        <div
          className={cx(
            'col-span-4 p-8 leading-none text-left bg-gray-100 lg:p-10 lg:col-span-7'
          )}
        >
          <h3 className="pb-8 text-3xl text-blue-700 lg:text-4xl xl:text-6xl font-semiBold lg:pb-10">
            Überblick
          </h3>
          <p className="pb-6 leading-relaxed whitespace-normal lg:text-lg">
            {blok?.overview_description}
          </p>
          <p className="leading-relaxed whitespace-normal lg:text-lg">
            <span className="font-semiBold">Quartier: </span>
            {blok?.overview_district}
          </p>
          <p className="leading-relaxed whitespace-normal lg:text-lg">
            <span className="font-semiBold">Hauswartung: </span>
            <span
              dangerouslySetInnerHTML={{ __html: blok?.overview_housekeeper }}
            />
          </p>
        </div>
      ) : (
        <div className="col-span-4 p-8 leading-none text-left bg-gray-100 lg:p-10 lg:col-span-12 xl:col-span-7">
          <h3 className="pb-8 text-3xl text-blue-700 lg:text-4xl xl:text-6xl font-semiBold lg:pb-10">
            Überblick
          </h3>
          <p className="pb-6 leading-relaxed whitespace-normal lg:text-lg">
            {blok?.overview_description}
          </p>
          <p className="leading-relaxed whitespace-normal lg:text-lg">
            <span className="font-semiBold">Quartier: </span>
            {blok?.overview_district}
          </p>
          <p className="leading-relaxed whitespace-normal lg:text-lg">
            <span className="font-semiBold">Hauswartung: </span>
            <span
              dangerouslySetInnerHTML={{ __html: blok?.overview_housekeeper }}
            />
          </p>
        </div>
      )}
      {blok?.data.length > 2 ? (
        <div className="col-span-4 p-8 leading-none text-left bg-gray-100 lg:p-10 lg:col-span-5">
          <h3 className="pb-8 text-3xl text-blue-700 lg:text-4xl xl:text-6xl font-semiBold lg:pb-10">
            Erstellung
          </h3>
          <ul>
            {blok?.creation_data?.tbody?.map(tr => (
              <li
                key={tr._uid}
                className="flex flex-col leading-relaxed lg:flex-row"
              >
                <span className="order-2 lg:w-2/3 lg:text-lg lg:order-1">
                  {tr.body[0].value}
                </span>
                <span className="order-1 lg:w-1/3 lg:text-lg lg:order-2 font-semiBold lg:font-regular">
                  {tr.body[1].value}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="col-span-4 p-8 leading-none text-left bg-gray-100 lg:row-start-1 lg:row-end-3 lg:col-start-8 lg:p-10 lg:col-span-5 ">
          <h3 className="pb-8 text-3xl text-blue-700 lg:text-4xl xl:text-6xl font-semiBold lg:pb-10">
            Erstellung
          </h3>
          {blok?.creation_data?.tbody?.map(tr2 => (
            <li
              key={tr2._uid}
              className="flex flex-col leading-relaxed lg:flex-row"
            >
              <span className="order-2 lg:w-2/3 lg:text-lg lg:order-1">
                {tr2.body[0].value}
              </span>
              <span className="order-1 lg:w-1/3 lg:text-lg lg:order-2 font-semiBold lg:font-regular">
                {tr2.body[1].value}
              </span>
            </li>
          ))}
        </div>
      )}

      <div className="flex flex-col col-span-4 p-4 text-left lg:items-center lg:text-center lg:justify-center lg:shadow-xl lg:p-10 bg-light-olive-green lg:col-span-12 street-data whitespace-nowrap lg:flex-row lg:flex-wrap">
        {blok?.zimmer1 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer1}</span>
            <span className="pl-4 text-white">1-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer1_5 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer1_5}</span>
            <span className="pl-4 text-white">1-1/2-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer2 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer2}</span>
            <span className="pl-4 text-white">2-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer2_5 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer2_5}</span>
            <span className="pl-4 text-white">2-1/2-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer3 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer3}</span>
            <span className="pl-4 text-white">3-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer3wheelchairs && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer3wheelchairs}</span>
            <span className="pl-4 text-white">
              3-Zimmerwohnungen (3 rollstuhlgängig)
            </span>
          </div>
        )}
        {blok?.zimmer3_5 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer3_5}</span>
            <span className="pl-4 text-white">3-1/2-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer4 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer4}</span>
            <span className="pl-4 text-white">4-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer4_5 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer4_5}</span>
            <span className="pl-4 text-white">4-1/2-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer4Duplex && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer4Duplex}</span>
            <span className="pl-4 text-white">4-1/2-Duplexwohnungen</span>
          </div>
        )}
        {blok?.zimmer5 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer5}</span>
            <span className="pl-4 text-white">5-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer5_5 && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer5_5}</span>
            <span className="pl-4 text-white">5-1/2-Zimmerwohnungen</span>
          </div>
        )}
        {blok?.zimmer5Duplex && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.zimmer5Duplex}</span>
            <span className="pl-4 text-white">5-1/2-Duplexwohnungen</span>
          </div>
        )}
        {blok?.garage && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.garage}</span>
            <span className="pl-4 text-white">Einstellgarage</span>
          </div>
        )}
        {blok?.e_parking && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.e_parking}</span>
            <span className="pl-4 text-white">E-Parkplätze</span>
          </div>
        )}
        {blok?.outdoor_parking && (
          <div className="px-4 py-2 text-base text-blue-700 lg:text-xl font-semiBold">
            <span>{blok?.outdoor_parking}</span>
            <span className="pl-4 text-white">Aussenparkplätze</span>
          </div>
        )}
      </div>
      {blok?.geo ? (
        <div
          className={cx(
            'col-span-4 row-start-6 text-left bg-gray-100 lg:col-span-12 min-h-80 lg:row-start-4 google-map xl:col-span-7',
            {
              'xl:row-start-4': blok?.data.length <= 2,
            }
          )}
        >
          {blok?.geo?.map(blk => (
            <Component blok={blk} key={blk._uid} />
          ))}
        </div>
      ) : null}
      <div className="col-span-4 p-8 text-left bg-gray-100 lg:p-10 lg:col-span-12 xl:col-span-5">
        <h3 className="pb-8 text-3xl leading-none text-blue-700 lg:text-4xl xl:text-6xl font-semiBold lg:pb-10">
          Renovation
        </h3>
        <p
          className="whitespace-normal lg:text-lg"
          dangerouslySetInnerHTML={{ __html: blok?.renovation_date }}
        />
        <p
          className="whitespace-normal lg:text-lg"
          dangerouslySetInnerHTML={{ __html: blok?.renovation_info }}
        />
      </div>
    </section>
  </SbEditable>
);

export default Masonry;
